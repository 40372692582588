
import Wrapper from "../Components/Wrapper";
import Screen from "../Components/Screen";
import ButtonContainer from "../Components/ButtonContainer";
import Button from "../Components/Button";
import React from "react";

const buttonValues = [
  "AC",
  "+-",
  "%",  
  "÷",
  "7",
  "8",
  "9",
  "x",
  "4",
  "5",
  "6",
  "-",
  "1",
  "2",
  "3",
  "+",
  "0",
  ".",
  "=",
];

function Calculator() {
  let [calc, setCalc] = React.useState({
    total: 0,
    stored: 0,
    operator: "",
  });

  const handleClick = (e) => {

    switch (e.target.innerHTML) {

      //*** AC PRESS ***//
      case "AC":
        setCalc({ total: 0, stored: 0, operator: "" });
        break;

      //*** SIGN PRESS ***//
      case "+-":
        setCalc((previous) => {
          let newTotal = parseFloat(previous.total) * -1;
          return { ...previous, total: newTotal };
        });

        break;

      //*** PERCENTAGE PRESS ***//
      case "%":
        setCalc((previous) => {
          return { ...previous, total: previous.total / 100 };
        });
        break;

      //*** OPERATOR PRESS ***//
      case "÷":
      case "x":
      case "-":
      case "+":
      if ( calc.stored !== 0 && calc.operator !== '' ) {
        setCalc((previous) => {
          let operator =
            previous.operator === "x"
              ? "*"
              : previous.operator === "÷"
              ? "/"
              : previous.operator;

          let newTotal = 0

          operator === '*' ? newTotal = previous.stored * previous.total 
            : operator === '/' ? newTotal = previous.stored / previous.total
            : operator === '+' ? newTotal = previous.stored + previous.total
            : newTotal = previous.stored - previous.total

          return { total: newTotal, stored: 0, operator: e.target.innerHTML };
        })
      } 
      else {
        setCalc((previous) => {
          return { ...previous, operator: e.target.innerHTML };
        });
      }
      break;
      

      //*** EQUALS PRESS ***//
      case "=":
        setCalc((previous) => {
          let operator =
            previous.operator === "x"
              ? "*"
              : previous.operator === "÷"
              ? "/"
              : previous.operator;

          let newTotal = 0

          operator === '*' ? newTotal = previous.stored * previous.total 
            : operator === '/' ? newTotal = previous.stored / previous.total
            : operator === '+' ? newTotal = parseFloat(previous.stored) + parseFloat(previous.total)
            : newTotal = previous.stored - previous.total

          return { total: parseFloat(newTotal.toFixed(6)), stored: 0, operator: "" };
        });
        break;

      //*** NUMBER PRESSES ***//
      default:
        // checks whether or not its the first input or not
        if (calc.total === 0 && e.target.innerHTML !== ".") {
          setCalc((previous) => {
            return { ...previous, total: e.target.innerHTML };
          });
        }
        
        else if (calc.operator !== '' && calc.stored === 0) {
          setCalc((previous) => {
            return { ...previous, stored: previous.total, total: e.target.innerHTML }
          })
        }

        else {
          setCalc((previous) => {
            return { ...previous, total: previous.total + e.target.innerHTML };
          });
        }

        break;
    }
  };

  console.log(calc);

  return (
    <Wrapper>
      <Screen value={calc.total} />

      <ButtonContainer>
        {buttonValues.map((btn, i) => {
          return (
            <Button
              key={i}
              value={btn}
              onClick={handleClick}
              active={false}
              className={
                btn === "AC" || btn === "+-" || btn === "%"
                  ? "button-utility"
                  : btn === "÷" ||
                    btn === "x" ||
                    btn === "-" ||
                    btn === "+" ||
                    btn === "="
                  ? "button-operator"
                  : btn === "0"
                  ? "buttonZero"
                  : "button"
              }
            />
          );
        })}
      </ButtonContainer>
    </Wrapper>
  );
}

export default Calculator;