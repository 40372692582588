import React from "react";
import '../Styles/AboutPage.css';

function About() {

  return (
    <section className='AboutPageWrapper' id='AboutPage'>
      <h2 className="AboutPage-Heading">About</h2>

      <div className="AboutMeContainer">
          <p>
            Hello, I'm William Forbes, a passionate Software Engineer. I have a deep love for full-stack development and a strong desire to create meaningful and innovative solutions.
          </p>

          <p>
            In my free time, you can find me working on personal projects, playing guitar, or serving as a couch for my cat while enjoying a cup of coffee . I believe in lifelong learning and am always exploring new skills and technologies to stay up to date with the latest trends.
          </p>

          <p>
            Let's connect and explore opportunities to work together or share experiences. Feel free to reach out at chase.forbes14@gmail.com or connect with me on LinkedIn or Github.
          </p>
      </div>

    </section>
  );
}

export default About;