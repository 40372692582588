
import React, { useEffect, useState, useRef } from 'react';

// Images/Videos
import videoSource from '../Videos/GreenPastelMountains.mp4';
import arrows from "../images/downarrow.svg";
import gitIcon from "../images/github-mark.svg";
import linkedLogo from "../images/linkedinLogo.svg";

//Styles
import '../Styles/Homepage.css';

const Homepage = () => {

    const [fadeIn, setFadeIn] = useState(false);
    const videoRef = useRef(null);
  
    useEffect(() => {

        //Video Playback Speed
        if (videoRef.current) {
          videoRef.current.playbackRate = 3;
        }

        setFadeIn(true); // Change the state
    }, []);

  return (
    <main className="Homepage-Wrapper">

      <video ref={videoRef} muted autoPlay controls={false} playsInline> 
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    
      <div className={fadeIn ? "HomepageHeroContainer" : "HiddenHeroContainer"}>
        <h1 className="Name">William Forbes</h1>
        <span className="Title">Software Enginner</span>

        <div className="SocialsContainer">
          <a 
            href="https://github.com/forbesw17?tab=repositories" target="blank">
            <img className='GitIcon' src={gitIcon} alt="gitIcon" />
          </a>
          <a
            href="https://www.linkedin.com/in/william-forbes-919663195" target="blank">
            <img className="LinkedInLogo" src={linkedLogo} alt="linkedInLogo" />
          </a>
        </div>

        <div className="ArrowContainer">
          <img className="Arrows" src={arrows} alt="arrows"></img>
        </div>

      </div>
    </main>
  );
};

export default Homepage;