
import React from "react";
import '../Styles/Project.css';
// import Calculator from '../Projects/Calculator/Components/Calculator';

const Project = (props) => {

  return (
    <div className='Project-Container'>

        <div className="Project-Showcase">{props.showcase}</div>

        <div className='Project-Description-Container'>
            <h5 className='Project-Title'>{props.title}</h5>
            <p className="Project-Description">{props.description}</p>
        </div>

    </div>
  );
}

export default Project;