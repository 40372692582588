import React from 'react';
import { Link } from 'react-scroll';
// import HomeIcon from '../images/HomeIcon.svg'
import '../Styles/Navbar.css'

const Navbar = () => {

    return (

    <nav className="NavWrapper">
        {/* <Link to="HompagePage" smooth={true} duration={1000}>Home</Link> */}
        <Link to="AboutPage" smooth={true} duration={1250}>About</Link>
        <Link to="ProjectsPage" smooth={true} duration={1250}>Projects</Link>
        {/* <Link to="ResumePage" smooth={true} duration={1250}>Resume</Link> */}
        <Link to="ContactPage" smooth={true} duration={1250}>Contact</Link>
    </nav>

    );
}

export default Navbar;