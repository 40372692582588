import React from "react";
import '../Styles/ProjectsPage.css';
import Project from '../Components/Project';
import Calculator from "../Projects/Calculator/Components/Calculator";

function ProjectsPage() {

  return (
    <section className='ProjectsPageWrapper' id='ProjectsPage'>

        <h3 className="ProjectsPage-Heading">Projects</h3>

        <Project 
          showcase= <Calculator/>
          title='Apple Calculator Clone'
          description='This project is an Apple Calculator Clone, created with the purpose
          of gaining a better understanding of React and React Hooks, specifically useState(), 
          and implementing CSS to achieve a design similar to an actual Apple calculator. The application 
          replicates the look and functionality of the Apple calculator found on iOS devices, providing users 
          with a familiar and intuitive interface.'
        />

        {/* <Project
          showcase=<Calculator/>
          title="Num Two"
          description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed doloribus atque perspiciatis 
          necessitatibus corporis! Quo incidunt enim asperiores dignissimos officia.'
        /> */}

        

    </section>
  );
}

export default ProjectsPage;