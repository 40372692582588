import './App.css';

import Homepage from './Components/Homepage';

import AboutPage from './Components/AboutPage';
import ProjectsPage from './Components/ProjectsPage';
import Navbar from './Components/Navbar';
import ContactPage from './Components/ContactPage';
// import ResumePage from './Components/ResumePage';

function App() {
  return (
    <div className="App">

      <Navbar/>

      <Homepage/>

      <AboutPage/>
      
      <ProjectsPage/>

      {/* <ResumePage/> */}

      <ContactPage/>

    </div>
  );
}

export default App;
